export default function Footer() {

    return (
        <footer>
            <div><br /></div>
            <div><br /></div>
            <h6>Code and Design © John Gray 2024</h6>
            <div><br /></div>
        </footer>
    );
}
